import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { ErrorCode, ErrorMessage } from "@/constant";
import { AccountModel } from "@/models";

export class LoginForm {
    @IsDefined() @IsNotEmpty() @MaxLength(50) account = '';
    @IsDefined() @IsNotEmpty() @MaxLength(50) password = '';
    @IsDefined() verification = '';
}
type response = { [key: string]: unknown };

@Component //不管有沒有都要寫，且必須放置於controller上方
export default class AdminLoginIndexController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private loginForm = new LoginForm();
    private verificationCode: { [key: string]: string } = {
        uid: "",
        imgSrc: ""
    };
    private errorMsg: { [key: string]: string } = {
        form: "",
        response: ""
    };
    private error = "";

    public created() {
        this.refreshCode(); //取得驗證碼圖片
    }

    private async refreshCode() {
        if (!this.doubleClick) {
            this.doubleClick = true;

            const timestamp = Math.floor(Date.now() / 1000).toString();
            const random = Math.floor(Math.random() * 1000).toString();
            this.verificationCode.uid = timestamp + random;

            const codeItem = {
                uniqueID: this.verificationCode.uid
            };
            this.verificationCode.imgSrc = await AccountModel.refreshCodeImg(codeItem); //通過model call api回傳所需資料
            this.doubleClick = false;
        }
    }

    private async validateAndSubmit() {
        validate(this.loginForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    const errorMsgMap: { [key: string]: string } = {
                        account: "帳號",
                        password: "密碼",
                        verification: "驗證碼"
                    };

                    this.errorMsg.form = "請填入";
                    result.forEach((item, index) => { //組合error 提示字 -> Invalid Account / Password / Verification Code
                        this.errorMsg.form += (index === 0) ? errorMsgMap[item.property] : " / " + errorMsgMap[item.property];
                    });
                    return;
                }

                this.errorMsg.form = "";
                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const codeItem = {
                        code: this.loginForm.verification,
                        uniqueID: this.verificationCode.uid
                    };

                    // const verificationCodeErrNo = await AccountModel.verificationCodeCheck(codeItem); //呼叫api 驗證驗證碼正確性

                    // if (verificationCodeErrNo !== ErrorCode.Success) {
                    //     this.errorMsg.form = ErrorMessage[verificationCodeErrNo];
                    //     this.doubleClick = false;
                    //     this.refreshCode();
                    //     return;
                    // }

                    const item = {
                        acc: this.loginForm.account,
                        pwd: this.loginForm.password
                    };
                    const data = await AccountModel.userLogin(item) as response;
                    if (data.ERR_CODE === ErrorCode.Success) {
                        const item = data.info as response;

                        window.localStorage.setItem('adminToken', data.auth as string);
                        window.localStorage.setItem('admin_aaid', item.aaid as string);
                        window.localStorage.setItem('admin_identity', item.identity as string);
                        window.localStorage.setItem('admin_sid', item.sid as string);
                        window.localStorage.setItem('admin_school', item.school as string);
                        window.localStorage.setItem('admin_name', item.name as string);
                        window.localStorage.setItem('admin_authority', item.authority as string);
                
                        this.doubleClick = false;
                        if (item.identity === '1') {
                            this.$router.push("/admin/account");
                        } else {
                            this.$router.push("/admin/teacher");
                        }
                    } else {
                        this.errorMsg.form = ErrorMessage[data.ERR_CODE as number];
                        this.refreshCode();
                        this.doubleClick = false;
                    }
                }
            }
        );
    }
}